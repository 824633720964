// Don't needs to be logged in to access these routes
// Don't needs have plan to access these routes
export const GET_PUBLIC_ROUTE_PATH = {
  LANDING: '/',
  SIGN_IN: '/signin',
  SIGN_UP: '/signup',
  FORGET_PASSWORD: '/forgotpassword',
  RESET_PASSWORD: '/resetpassword',
  PROFISSIONAL_LINK_CONFIRMATION: '/link-professional-approve',
  CUSTOMER_LINK_CONFIRMATION: '/link-costumer-approve',
  PAGE_404: '/404',
  PAGE_500: '/500',
  AUTH0_CALLBACK: '/auth0loginCallback',
  TEST_NEW_CASHFLOW_TABLE: '/test-new-cashflow-table',
};
