import jwt from 'jsonwebtoken';
import { toast } from 'react-toastify';
import { all, call, put, takeEvery } from 'redux-saga/effects';
import { clearToken, getToken } from '../../library/helpers/utility';
import api from '../../services/api';
import actions from './actions';

function* loginRequestSaga({ payload }) {
  try {
    yield put({ type: actions.LOADING, isLoading: true });

    const { data } = yield call(api.post, '/auth', {
      email: payload.email,
      password: payload.password,
    });

    api.defaults.headers['Authorization'] = `Bearer ${data.accessToken}`;

    const { data: myInfo } = yield call(api.get, `/user/me`);

    if (!myInfo) throw new Error('user not found');

    const refreshUser = {
      ...myInfo,
      permissions: [myInfo.userType],
    };

    toast.success('Seja bem vindo');

    yield put({
      type: actions.LOGIN_SUCCESS,
      token: data.accessToken,
      user: refreshUser,
      profile: 'Profile',
      isLoggedIn: true,
      isLoading: false,
    });

    payload.history.push('/dashboard');
    payload.history.go(0);
  } catch (error) {
    toast.error('Email ou senha inválidos');
    yield put({ type: actions.LOGIN_ERROR });
  } finally {
    payload.setIsLoading(false);
    yield put({ type: actions.LOADING, isLoading: false });
  }
}

function* updateUserSaga({ payload }) {
  const token = yield localStorage.getItem('token');
  payload.permissions = [payload.userType];
  delete payload.userType;
  yield put({
    type: actions.UPDATE_USER_SUCCESS,
    token: token,
    user: payload,
    isLoading: false,
    isLoggedIn: true,
    profile: 'Profile',
  });
}

function* loginSuccessSaga(payload) {
  yield localStorage.setItem('token', payload.token);
  yield localStorage.setItem('user', JSON.stringify(payload.user));
}

function* logoutSaga() {
  yield call(clearToken);
}

function* checkAuthorizationSaga() {
  yield put({ type: actions.LOADING, isLoading: true });
  const token = getToken().get('token');
  const user = getToken().get('user');
  const selectedCustomer = getToken().get('selectedCustomer');

  if (token && user) {
    try {
      const decodedToken = jwt.decode(token);
      if (decodedToken?.exp && decodedToken.exp > Date.now() / 1000) {
        api.defaults.headers['Authorization'] = `Bearer ${token}`;

        const options = user.permissions[0] === 'PROFESSIONAL' ? { params: { construction: selectedCustomer?.constructionId } } : {};

        const { data } = yield call(api.get, `/user/me`, options);

        if (!data) throw new Error('user not found');

        const refreshUser = {
          ...data,
          permissions: [data.userType],
        };

        yield put({
          type: actions.LOGIN_SUCCESS,
          token,
          user: refreshUser,
          profile: 'Profile',
          isLoggedIn: true,
          isLoading: false,
        });
      } else {
        yield put({ type: actions.LOGOUT });
      }
    } catch (error) {
      yield put({ type: actions.LOGOUT });
    }
  } else {
    yield put({ type: actions.LOGOUT });
  }
}

function* rootSaga() {
  yield all([
    takeEvery('LOGIN_REQUEST', loginRequestSaga),
    takeEvery('UPDATE_USER', updateUserSaga),
    takeEvery(actions.LOGIN_SUCCESS, loginSuccessSaga),
    takeEvery(actions.LOGIN_ERROR, function* () {}),
    takeEvery(actions.LOGOUT, logoutSaga),
    takeEvery(actions.CHECK_AUTHORIZATION, checkAuthorizationSaga),
  ]);
}

export default rootSaga;
