import clone from 'clone';
import { newSupplier } from './config';
import actions from './actions';

const initState = {
  suppliers: [],
  initialSuppliers: false,
  currentSupplier: {},
  editableSupplier: {},
  isNewSupplier: false,
  enableEditView: false,
};

export default function cardReducer(state = initState, { type, ...action }) {
  switch (type) {
  case actions.UPDATE_SUPPLIER: {
    const currentSupplier = action.supplier ? action.supplier : state.currentSupplier;
    return {
      ...state,
      suppliers: action.suppliers,
      currentSupplier: clone(currentSupplier),
      initialSuppliers: true,
      isNewSupplier: false,
      enableEditView: false,
    };
  }
  case actions.SELECT_CURRENT_SUPPLIER: {
    const suppliers = state.suppliers;
    const index = suppliers.map((supplier) => supplier.id).indexOf(action.id);
    const isNewSupplier = index === -1;
    const currentSupplier = isNewSupplier
      ? {
        id: action.id,
        number: `#${action.id}`,
        key: action.id,
        ...newSupplier,
      }
      : suppliers[index];
    const enableEditView = isNewSupplier;
    return {
      ...state,
      currentSupplier,
      isNewSupplier,
      enableEditView,
      editableSupplier: clone(currentSupplier),
    };
  }
  case actions.TOGGLE_VIEW:
    return {
      ...state,
      enableEditView: action.view,
      editableSupplier: clone(state.currentSupplier),
    };
  case actions.UPDATE_EDIT_SUPPLIER:
    return {
      ...state,
      editableSupplier: clone(action.supplier),
    };
  default:
    return state;
  }
}
