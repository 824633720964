import actions from './actions';

const initState = { token: null, user: null, isLoggedIn: null, isLoading: true };

export default function authReducer(state = initState, action) {
  switch (action.type) {
  case actions.LOADING:
    return {
      token: null,
      user: null,
      isLoggedIn: null,
      isLoading: action.isLoading,
    };
  case actions.LOGIN_SUCCESS:
    return {
      token: action.token,
      user: action.user,
      isLoggedIn: action.isLoggedIn,
      isLoading: false,
    };
  case actions.UPDATE_USER_SUCCESS:
    return {
      token: action.token,
      user: action.user,
      isLoggedIn: action.isLoggedIn,
      isLoading: false,
    };
  case actions.LOGOUT:
    return { ...initState, isLoading: null };

  case actions.LOGIN_ERROR:
    return { ...initState, isLoading: false };
  default:
    return state;
  }
}
