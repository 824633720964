import React, { Suspense, lazy } from 'react';
import { useSelector } from 'react-redux';
import { Redirect, Route, BrowserRouter as Router, Switch } from 'react-router-dom';
import { ErrorBoundary } from './ErrorBoundary';
import Loader from './components/utility/loader';
import { GET_PUBLIC_ROUTE_PATH } from './route.constants';

const Dashboard = lazy(() => import('./containers/Dashboard/Dashboard'));

export const publicRoutes = [
  { path: GET_PUBLIC_ROUTE_PATH.LANDING, exact: true, component: lazy(() => import('./containers/Pages/SignIn/SignIn')) },
  { path: GET_PUBLIC_ROUTE_PATH.SIGN_IN, component: lazy(() => import('./containers/Pages/SignIn/SignIn')) },
  { path: GET_PUBLIC_ROUTE_PATH.SIGN_UP, component: lazy(() => import('./containers/Pages/SignUp/SignUp')) },
  { path: GET_PUBLIC_ROUTE_PATH.FORGET_PASSWORD, component: lazy(() => import('./containers/Pages/ForgotPassword/ForgotPassword')) },
  { path: `${GET_PUBLIC_ROUTE_PATH.RESET_PASSWORD}/:id`, component: lazy(() => import('./containers/Pages/ResetPassword/ResetPassword')) },
  {
    path: `${GET_PUBLIC_ROUTE_PATH.PROFISSIONAL_LINK_CONFIRMATION}/:token`,
    component: lazy(() => import('./containers/Pages/linkApprove/Professional/LinkProfessional')),
  },
  {
    path: `${GET_PUBLIC_ROUTE_PATH.CUSTOMER_LINK_CONFIRMATION}/:token`,
    component: lazy(() => import('./containers/Pages/linkApprove/Customer/LinkCustomer')),
  },
];

const PrivateRoute = ({ children, ...rest }) => {
  const isLoading = useSelector((state) => state.Auth.isLoading);
  const isLoggedIn = useSelector((state) => state.Auth.isLoggedIn);

  if (isLoading)
    return (
      <>
        <Loader />
      </>
    );
  if (!isLoggedIn) return <Redirect to={GET_PUBLIC_ROUTE_PATH.SIGN_IN} />;

  return (
    <>
      <Route {...rest} render={() => children} />
    </>
  );
};

export const Routes = () => {
  return (
    <ErrorBoundary>
      <Suspense fallback={<Loader />}>
        <Router>
          <Switch>
            {publicRoutes.map(({ path, exact, component: Component }) => (
              <Route key={path} path={path} exact={exact}>
                <Component />
              </Route>
            ))}
            <PrivateRoute path="/dashboard">
              <Dashboard />
            </PrivateRoute>
            <Redirect to={GET_PUBLIC_ROUTE_PATH.SIGN_IN} />
          </Switch>
        </Router>
      </Suspense>
    </ErrorBoundary>
  );
};
